import React, { useState, useContext } from "react";
import DataContext from "context/DataContext";
import {
  PencilSquare,
  XCircleFill,
  CheckSquareFill,
} from "react-bootstrap-icons";
import PurebrandToast from "components/Toasts/Toast";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import AuthContext from "context/AuthContext";
import ImgComponent from "components/imgComponent";

const ProductList = ({ products }) => {
  const { productListAllTag, storeAdminProductList, updateProductTagStore } =
    useContext(DataContext);
  const { user } = useContext(AuthContext);
  const [showToast, setShowToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [failed, setFailed] = useState(false);

  const toastPopup = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const handleEdit = async (e) => {
    setIsLoading(true);
    let tag = e?.tag;
    try {
      let res = await updateProductTagStore(e?.id, { tag });
      if (res?.status === 200) {
        setShowEdit(false);
        setIsLoading(false);
        toastPopup();
        await storeAdminProductList(`?order=true`);
      }
    } catch (error) {
      toastPopup();
      setFailed(true);
    }
  };

  if (isLoading) {
    <PurebrandLoader />;
  }

  return (
    <>
      <PurebrandToast
        show={showToast}
        onClose={handleCloseToast}
        title={!failed ? "Success" : "Failed"}
        message={
          !failed
            ? "Successfully Updated Category."
            : "Category updatinfg failed"
        }
      />
      <tr>
        <td>
          <ImgComponent
            className="border-1 rounded-1"
            src={products?.image}
            height="50px"
            width="auto"
          />
        </td>
        <td>
          <div className="h6">{products?.name}</div>
        </td>
        <td className="h6">
          {!showEdit ? (
            <div>{products?.tag?.name}</div>
          ) : (
            <div>
              <select
                className="form-select"
                defaultValue={"selected"}
                onChange={(e) => {
                  let data = {
                    tag: e?.target?.value,
                    id: products?.id,
                  };
                  handleEdit(data);
                }}
              >
                <option disabled value="selected">
                  Select Category
                </option>
                {productListAllTag?.map((list) => {
                  return <option value={list?.id}>{list.name} </option>;
                })}
              </select>
            </div>
          )}
        </td>
        {user.role !== 5 && (
          <td className="h6">
            {showEdit ? (
              <div className="d-flex">
                {/* <div
                className="p-1"
                style={{
                  border: "1px solid #808080",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
              >
                <CheckSquareFill
                  size={"20px"}
                  onClick={() => {
                    handleEdit();
                  }}
                  color="green"
                /> 
              </div>*/}
                {/* <div
                className="p-1"
                style={{
                  border: "1px solid #808080",
                  borderRadius: "5px",
                }}
              > */}
                <XCircleFill
                  size={"20px"}
                  color="red"
                  onClick={() => {
                    setShowEdit(false);
                  }}
                />
                {/* </div> */}
              </div>
            ) : (
              <PencilSquare
                size="25px"
                onClick={() => {
                  setShowEdit(true);
                }}
                style={{
                  padding: "1px",
                }}
              />
            )}
          </td>
        )}
      </tr>
    </>
  );
};

export default React.memo(ProductList);
